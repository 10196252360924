<template>
    <div class="m-1">
        <h3 v-if="has_code"><span class="badge badge-success"><i class="fa fa-cog fa-spin"></i> Redirecting...</span></h3>
        <h3 v-else><span class="badge badge-xl badge-danger" ><i class="fa fa-question-circle"></i> "code" parameter required</span></h3>
    </div>
</template>

<script>
    export default {
        mounted() {
            if(this.$route.query.code) {
                this.$http.post('exact/auth-code', {
                    auth_code: this.$route.query.code
                }).then((response) => {
                    this.$router.push({name: 'settings.index'});
                });
            } else {
                this.has_code = false;
            }
        },
        data() {
            return {
                has_code: true
            }
        }
    }
</script>
